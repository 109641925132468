.sidenav {
    height: 100vh;
    width: 255px;
    z-index: 1;
    overflow-x: hidden;
    padding-top: 20px;
    border-right: 1px solid #ccc;
  }
  
  .sidenav a {
    padding: 1px 8px 1px 16px;
    text-decoration: none;
    font-size: 20px;
    color: black;
    display: block;
  }
  
  .sidenav a:hover {
    color: #141e8c;
  }
  .menu-layout {
      display: flex;
      flex-direction: row;
      border-top: 1px solid #ccc;
  }
.research-text {
  font-style: italic;
  text-align: center;
  margin-top: 50%;
}